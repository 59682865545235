import React, { useState } from 'react';
import image5 from "../../../assests/images/image5.png";
import image6 from "../../../assests/images/image6.png";
import image7 from "../../../assests/images/image7.jpeg";
import image8 from "../../../assests/images/image8.jpeg";
import image9 from "../../../assests/images/image9.jpeg";
import image10 from "../../../assests/images/image10.jpg";
import './women.css';

const products = [
  { id: 1, name: 'Dress 1', image: image8 },
  { id: 2, name: 'Dress 2', image: image9 },
  { id: 3, name: 'Dress 3', image: image10 },
];

const ProductList = () => {
  const [selectedImage, setSelectedImage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage('');
  };

  return (
    <div className="product-list">
      <h2>Our Dresses</h2>
      <div className="row">
        {products.map((product) => (
          <div className="col-md-4 mb-3" key={product.id}>
            <div className="card">
              <img
                src={product.image}
                alt={product.name}
                className="card-img-top product-image"
                onClick={() => openModal(product.image)}
                style={{ cursor: 'pointer' }}
              />
              <div className="card-body">
                <h5 className="card-title">{product.name}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className={`modal fade ${modalOpen ? 'show' : ''}`}
        style={{ display: modalOpen ? 'block' : 'none' }}
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Selected Dress</h5>
              <button type="button" className="close" onClick={closeModal}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <img src={selectedImage} alt="Selected" className="modal-image img-fluid" />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && <div className="modal-backdrop fade show" />}
    </div>
  );
};

export default ProductList;
