import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { motion } from "framer-motion"; 
import "./terms.css"
const TermsAndConditions = () => {
  return (
    <Container className="mt-0" style={{padding:"120px 0px"}}>
      <Row className="justify-content-center">
        <Col lg={12} md={10} sm={12}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Card className="shadow-lg border-0 rounded-lg bg-light">
              <Card.Body>
                <Card.Title className="text-center text-info mb-3">Terms and Conditions</Card.Title>
                {/* <Card.Text className="text-center text-muted">
                  Last updated: [Date]
                </Card.Text> */}
                <hr />
                <h4 className="text-info">Welcome to <strong style={{fontWeight:'800'}}>Sri Sakthi Wood Works </strong></h4>
                <p>
                  By accessing or using our website, you agree to comply with the following terms and conditions. If you do not agree, please do not use our site.
                </p>
                <div className="accordion" id="termsAccordion">
                  <div className="accordion-item">
                    <h5 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        1. General Information
                      </button>
                    </h5>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#termsAccordion"
                    >
                      <div className="accordion-body">
                        These terms govern your use of our website and the purchase of products from <strong style={{fontWeight:'800'}}>Sri Sakthi Wood Works </strong>.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h5 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        2. Product Information
                      </button>
                    </h5>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#termsAccordion"
                    >
                      <div className="accordion-body">
                        We strive to provide accurate descriptions and images of our products. However, we cannot guarantee that the colors or details will be accurate due to variations in screens.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h5 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        3. Ordering and Payment
                      </button>
                    </h5>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#termsAccordion"
                    >
                      <div className="accordion-body">
                        <ul>
                          <li>By placing an order, you confirm that you are at least 18 years old or have parental consent.</li>
                          <li>Payments can be made through [payment methods]. All transactions are secure.</li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h5 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        4. Shipping Policy
                      </button>
                    </h5>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#termsAccordion"
                    >
                      <div className="accordion-body">
                        We ship to [locations]. Delivery times may vary. Shipping costs will be calculated at checkout.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h5 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        5. Intellectual Property
                      </button>
                    </h5>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#termsAccordion"
                    >
                      <div className="accordion-body">
                        All content on this site, including text, graphics, logos, and images, is the property of <strong style={{fontWeight:'800'}}>Sri Sakthi Wood Works </strong> and is protected by copyright laws.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h5 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        6. Limitation of Liability
                      </button>
                    </h5>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#termsAccordion"
                    >
                      <div className="accordion-body">
                        <strong style={{fontWeight:'800'}}>Sri Sakthi Wood Works </strong> is not liable for any indirect, incidental, or consequential damages arising from your use of the site or products purchased.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h5 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button collapsed"
                        // type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="false"
                        aria-controls="collapseSeven"
                      >
                        7. Changes to Terms
                      </button>
                    </h5>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#termsAccordion"
                    >
                      <div className="accordion-body">
                        We may update these terms from time to time. Any changes will be posted on this page, and your continued use of the site constitutes acceptance of the new terms.
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h5 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        8. Governing Law
                      </button>
                    </h5>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#termsAccordion"
                    >
                      <div className="accordion-body">
                        These terms are governed by the laws of [Your State/Country].
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <h5>If you have any questions, please contact us at:</h5>
                <p>[Contact Information]</p>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsAndConditions;
