import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Card from '../../../components/common/cards/index';
// import image1 from "../../../assests/images/image1.jpg";
// import image2 from "../../../assests/images/images2.png";
// import image3 from "../../../assests/images/image3.png";
// import image4 from "../../../assests/images/image4.png";
// import image5 from "../../../assests/images/image5.png";
// import image6 from "../../../assests/images/image6.png";
import image1 from "../../../assests/all-images/pooja-mandapam/image-1.jpg";
import image2 from "../../../assests/all-images/pooja-mandapam/image-2.jpg";
import image3 from "../../../assests/all-images/pooja-mandapam/image-3.jpg";
import image4 from "../../../assests/all-images/pooja-mandapam/image-4.jpg";
import image5 from "../../../assests/all-images/pooja-mandapam/image-5.jpg";
import image6 from "../../../assests/all-images/pooja-mandapam/image-6.jpg";
import '../products.css';
const Chairs = () => {
    const productData = [
        { id: 1, name: 'Modern Door', price: '$199.99', shortDescription: 'A stylish modern door for your home.', image: image1 },
        { id: 2, name: 'Wooden Chair', price: '$89.99', shortDescription: 'A comfortable wooden chair for your living room.', image: image2 },
        { id: 3, name: 'Elegant Table', price: '$299.99', shortDescription: 'A beautiful table made from high-quality wood.', image: image3 },
        { id: 4, name: 'Stylish Bookshelf', price: '$149.99', shortDescription: 'A spacious bookshelf to organize your books.', image: image4 },
        { id: 5, name: 'Classic Cabinet', price: '$399.99', shortDescription: 'A classic cabinet with ample storage space.', image: image5 },
        { id: 6, name: 'Outdoor Swing', price: '$249.99', shortDescription: 'A relaxing swing for your backyard.', image: image6 },
    ];

    return (
        <div className="container mt-0 " style={{padding:'120px 10px '}}>
            <div className="banner animate__animated animate__bounceIn py-5" >
                <h1>Exclusive Offers on Pooja Mandapam</h1>
                <p>Up to 50% off on selected items!</p>
                <div className="icons">
                    <FontAwesomeIcon icon={faTag} className="icon" />
                    <FontAwesomeIcon icon={faShoppingCart} className="icon" />
                </div>
                <div className='text-center' id='pooja-mandapam'><a href='#pooja-mandapam' className="btn btn-danger" style={{width:'200px'}}>Shop Now</a></div>
                
            </div>
            <h2 className="my-4" >Pooja Mandapam</h2>
            <div className="row" >
                {productData.map(product => (
                    <div key={product.id} className="col-lg-4 col-md-6 mb-4">
                        <Card 
                            image={product.image} 
                            name={product.name} 
                            price={product.price} 
                            shortDescription={product.shortDescription} 
                            productId={product.id} 
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Chairs;
