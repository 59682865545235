import React, { useState, useEffect } from 'react';  
import { useParams } from 'react-router-dom';  
import './productDetail.css'

const ProductDetail = () => {
  const { id } = useParams();  // Get product ID from the URL

  const sampleProducts = [
    {
        id: 1,
        name: 'Modern Door',
        price: '$199.99',
        shortDescription: 'A stylish modern door for your home.',
        image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSdosIWg5360Uw-Ei-n4hoVjzulkgxOsnGl9Q&s'
    },
  ];

  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Simulate fetching the product data
    const fetchedProduct = sampleProducts.find(p => p.id === parseInt(id));
    if (fetchedProduct) {
      setProduct(fetchedProduct);  // Set the product if it exists
    }
  }, [id]);

  // If the product is not found or still loading
  if (!product) {
    return <div>Loading...</div>;
  }

  return (
    <div className="product-detail-container">
      <div className="product-detail-image">
        <img src={product.image} alt={product.name} className="zoom-image" />
      </div>
      <div className="product-detail-info">
        <h2>{product.name}</h2>
        <p className="price">{product.price}</p>
        <p>{product.shortDescription}</p>
      </div>
    </div>
  );
};

export default ProductDetail;
