
import React, { useState } from 'react';
import './enquiry.css'; 
import cardDetail from "../../assests/images/cardDetail.jpg"
const Enquiry = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));  
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="enquiry-page" style={{padding:'120px 0px'}}>
      <div className="enquiry-container container">
        <div className="product-details">
          <h2>Timber Product</h2>
          <p>
            This is a high-quality timber product designed to meet your needs. Whether you're building furniture,
            decking, or any other timber-related project, our timber is sustainably sourced and built to last.
          </p>
          <img src={cardDetail} alt="Timber Product" className="product-image" />
        </div>

        <div className="form-section">
          <h1>Timber Product Enquiry</h1>
          <p>Fill out the form below to enquire about our timber products. We will get back to you soon.</p>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Full Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Your Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
            </div>

            <button type="submit">Submit Enquiry</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Enquiry
