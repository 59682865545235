import React, { useState } from 'react';
import image1 from "../../../assests/images/image1.png";
import image2 from "../../../assests/images/images2.png";
import image3 from "../../../assests/images/image3.png";
import image4 from "../../../assests/images/image4.png";
import image5 from "../../../assests/images/image5.png";
import image6 from "../../../assests/images/image6.png";
import image7 from "../../../assests/images/image7.jpeg";
import image8 from "../../../assests/images/image8.jpeg";
import image9 from "../../../assests/images/image9.jpeg";
import image10 from "../../../assests/images/image10.jpg";
import { Carousel, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './kids.css';

const Kids = () => {
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (image) => {
    setSelectedImage(image);
    setShow(true);
  };
  const products = [
    {
      id: 1,
      name: 'Elegant Evening Dress',
      price: '120.00',
      discount: '20%',
      image: image5,
      description: 'A timeless piece perfect for formal occasions.',
      material: 'Silk blend',
      availableSizes: ['S', 'M', 'L'],
      ratings: 4.8,
      reviewsCount: 32,
    },
    {
      id: 2,
      name: 'Summer Floral Dress',
      price: '80.00',
      discount: '15%',
      image: image4,
      description: 'Lightweight and breezy, ideal for sunny days.',
      material: 'Cotton',
      availableSizes: ['XS', 'S', 'M', 'L'],
      ratings: 4.5,
      reviewsCount: 25,
    },
    {
      id: 3,
      name: 'Chic Boho Dress',
      price: '85.00',
      discount: '10%',
      image: image3,
      description: 'Embrace your inner flower child with this bohemian.',
      material: 'Viscose',
      availableSizes: ['M', 'L', 'XL'],
      ratings: 4.7,
      reviewsCount: 18,
    },
    {
      id: 4,
      name: 'Classic White Dress',
      price: '90.00',
      discount: '10%',
      image: image1,
      description: 'A wardrobe staple that can be dressed up or down.',
      material: 'Linen',
      availableSizes: ['S', 'M', 'L', 'XL'],
      ratings: 4.6,
      reviewsCount: 22,
    },
    {
      id: 5,
      name: 'Bold Red Summer Dress',
      price: '75.00',
      discount: '100%',
      image: image2,
      description: 'Make a statement with this vibrant red numbe and more.',
      material: 'Polyester',
      availableSizes: ['S', 'M'],
      ratings: 4.9,
      reviewsCount: 40,
    },
    {
      id: 6,
      name: 'Casual Striped Dress',
      price: '70.00',
      discount: '10%',
      image: image6,
      description: 'Perfect for a day out with friends, casual and chic.',
      material: 'Jersey',
      availableSizes: ['XS', 'S', 'M', 'L'],
      ratings: 4.4,
      reviewsCount: 10,
    },
  ];

  return (
    <div className="dress-shop">
      <div className="hero-carousel-container">
        <Carousel className="custom-carousel" interval={2000}>
          <Carousel.Item style={{ width: '100%', height: '100%' }}>
            <Carousel.Caption style={{ width: '100%', height: '100%', padding: '0px', position: 'static' }}>
              <img src={image7} className="d-block w-100 h-100 slide-in" alt="Banner 1" />
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item style={{ width: '100%', height: '100%' }}>
            <Carousel.Caption style={{ width: '100%', height: '100%', padding: '0px', position: 'static' }}>
              <img src={image8} className="d-block w-100 h-100 slide-in" alt="Banner 2" />
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item style={{ width: '100%', height: '100%' }}>
            <Carousel.Caption style={{ width: '100%', height: '100%', padding: '0px', position: 'static' }}>
              <img src={image9} className="d-block w-100 h-100 slide-in" alt="Banner 2" />
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item style={{ width: '100%', height: '100%' }}>
            <Carousel.Caption style={{ width: '100%', height: '100%', padding: '0px', position: 'static' }}>
              <img src={image10} className="d-block w-100 h-100 slide-in" alt="Banner 2" />
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="container mt-4">
        <div className="row justify-content-center">
          {products.map(product => (
            <div className="col-lg-4 col-md-6 col-sm-10 mb-4" key={product.id}>
              {/* <Link to={`/ProductDetail/${product.id}`} className="text-decoration-none"> */}
              <div className="card h-100">
                <div
                  className="image-container"
                  onClick={() => handleShow(product.image)}
                >
                  <img
                    src={product.image}
                    alt={product.name}
                    className="card-img-top"
                  /> 
                  <div className="overlay">
                    <span className="large-number">{product.discount}</span>
                    <p className="product-name">{product.name}</p>
                  </div>
                </div>
                <div className="card-body">
                  <h3 className="card-title">{product.name}</h3>
                  <p className="card-price">
                    <strong>${product.price}</strong>
                  </p>
                  <p className="card-description">
                    {product.description}
                  </p>
                  <p className="card-material">
                    🧵 Material: <em>{product.material}</em>
                  </p>
                  <p className="card-sizes">
                    📏 Available Sizes: {product.availableSizes.join(', ')}
                  </p>
                  <div className="ratings">
                    ⭐ <span>{product.ratings}</span> | <span>({product.reviewsCount} reviews)</span>
                  </div>
                  <div className='mt-2'>
                    <button className="btn btn-success">🛒 Add to Cart</button>
                  </div>

                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered className="custom-modal">
        <Modal.Header className='' closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Selected" className="img-fluid h-100 w-100" />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={handleClose}>Close</button>
        </Modal.Footer>
      </Modal>


    </div>

  );
};

export default Kids;
