
import React from 'react';
import './card.css'; 

const Card = ({ image, name, price, shortDescription, productId }) => {
    return (
        <div className="product-item text-center animate__animated animate__fadeIn">
             <img src={image} alt={name} className="card-img" />
            <div className='mt-1'>
            <h2 className="h5">{name}</h2>
            <p className="price">{price}</p>
            <p className="short-description">{shortDescription}</p>
            <a href={`/enquiry`} className="btn btn-primary">
             Enquiry
            </a>
            </div>

        </div>
    );
};

export default Card;
