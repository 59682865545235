import { useState, useRef, useEffect } from "react";
import "./help.css"
import telephone from "../../assests/images/telephone.png"
import upload from "../../assests/images/upload.png"
import whatsapp from "../../assests/images/whatsapp.png"
import button from "../../assests/images/button.png"
import plus from "../../assests/images/plus.png"
import arrow from "../../assests/images/arrow.png"
export const Help = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [showWhatsAppIcons, setShowWhatsAppIcons] = useState(false);

    const handleScroll = () => {
        setIsVisible(window.scrollY > 100);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isBrowser = () => typeof window !== 'undefined';
    const scrollToTop = () => {
        if (isBrowser()) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const openWhatsAppChat = () => {
        const phoneNumber = '+919342373853'; // Replace with the desired phone number
        const whatsappLink = `https://wa.me/${phoneNumber}`;
        window.open(whatsappLink, '_blank');
    };

    const handleCall = () => {
        const phoneNumber = "+919342373853"; // Replace with your phone number
        window.location.href = `tel:${phoneNumber}`;
    };

    const toggleWhatsAppIcons = () => {
        setShowWhatsAppIcons(!showWhatsAppIcons);
    };

    return (
        <div>
            {showWhatsAppIcons && (
                <div className="whatsapp-icons" style={{
                    position: 'fixed', right: '12px', bottom: '110px', display: 'flex', flexDirection: 'column', alignItems: "center", gap: "25px",
                    padding: "0px 7px 23px 0px", zIndex: "999"
                }}>
                    <div onClick={openWhatsAppChat} className="whatsapp-icon">
                        <img src={whatsapp} style={{ width: '45px' }} alt="WhatsApp" />
                    </div>
                    <div onClick={handleCall} className="whatsapp-call-icon">
                        <img src={telephone} style={{ width: '40px' }} alt="Call" />
                    </div>
                </div>
            )}
            <div className="up-arrow-icon" style={{ position: 'fixed', right: '1px', bottom: '2px' }}>
                <button onClick={scrollToTop} style={{
                    borderRadius: '50%', width: "35px", height: "35px", display: "flex",
                    justifyContent: "center", alignItems: "center",backgroundColor:'transparent' 
                }} className={`scrollToTopButton ${isVisible ? 'visible' : ''}`}>
                    <img style={{ width: '100%', borderRadius: '50%', width: "40px"}} src={arrow} />
                </button>
            </div>
            <div className="arrow-icon" style={{ position: 'fixed', right: '15px', bottom: '60px', transform: 'translateY(-50%)', zIndex: "999" }}>
                <span onClick={toggleWhatsAppIcons} style={{
                    border: 'none', borderRadius: '50%', width: "40px", height: "40px", display: "flex",
                    justifyContent: "center"
                }}>
                    {
                        showWhatsAppIcons ?
                            <div >   <img style={{ width: '100%', color: "white", fontWeight: "bolder", cursor: "pointer" }} src={button} /> </div>
                            :
                            <div >  <img style={{ width: '100%', fontWeight: "bolder", background: "white", borderRadius: '50%', cursor: "pointer" }} src={plus} /></div>
                    }
                </span>
            </div>
        </div>
    );
};
export default Help