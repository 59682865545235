import React from "react";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import { motion } from "framer-motion"; // Optional animation library
import './refund.css'; // You can create a specific CSS file for styling if needed

const RefundPolicy = () => {
  return (
    <Container className="mt-0" style={{padding:"120px 0px"}}> 
      <Row className="justify-content-center">
        <Col lg={12} md={12} sm={12}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Card className="shadow-lg border-0 rounded-lg bg-light">
              <Card.Body>
                <Card.Title className="text-center text-info mb-3">Refund Policy</Card.Title>
                {/* <Card.Text className="text-center text-muted">
                  Last updated: [Date]
                </Card.Text> */}
                <hr />
                <h4 className="text-info">At Sri Sakthi Woods, we strive to ensure our customers are satisfied with their purchases.</h4>
                <p>
                  If you are not completely satisfied, please review our refund policy below:
                </p>
                
                <Accordion defaultActiveKey="0">
                  {/* Eligibility for Returns */}
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>1. Eligibility for Returns</Accordion.Header>
                    <Accordion.Body>
                      You may return items within [number] days of receipt for a full refund or exchange. 
                      Items must be unworn, unused, and in their original packaging.
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Exclusions */}
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>2. Exclusions</Accordion.Header>
                    <Accordion.Body>
                      Certain items, such as [list any exclusions, e.g., custom items, sale items], are non-returnable.
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Return Process */}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>3. Return Process</Accordion.Header>
                    <Accordion.Body>
                      To initiate a return, please contact us at [contact information]. We will provide instructions 
                      for returning your item.
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Refunds */}
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>4. Refunds</Accordion.Header>
                    <Accordion.Body>
                      Once we receive your returned item, we will process your refund to the original payment 
                      method within [number] days.
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Shipping Costs */}
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>5. Shipping Costs</Accordion.Header>
                    <Accordion.Body>
                      Customers are responsible for return shipping costs unless the item is defective or 
                      incorrect.
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Damaged or Defective Items */}
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>6. Damaged or Defective Items</Accordion.Header>
                    <Accordion.Body>
                      If you receive a damaged or defective item, please contact us within [number] days of 
                      receipt for a replacement or refund.
                    </Accordion.Body>
                  </Accordion.Item>

                  {/* Changes to the Policy */}
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>7. Changes to the Policy</Accordion.Header>
                    <Accordion.Body>
                      We may update this refund policy occasionally. Any changes will be posted on this page, 
                      and your continued use of the site constitutes acceptance of the new policy.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <hr />
                <h5>If you have any questions about your order or our refund policy, please reach out to us at:</h5>
                <p>[Contact Information]</p>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
};

export default RefundPolicy;
