import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navigation';
import Home from "./pages/home/index";
import About from "./pages/about/index";
import Contact from "./pages/contact/index";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Help from "./components/layout/help";
import Doors from "./pages/products/doors";
import Footer from "./pages/footer/index";
import Kids from "./pages/fashion&design/kids/index"
import Women from "./pages/fashion&design/women/index"
// import ProductDetail from "./pages/products/index"
import TermsAndConditions from "./pages/terms&condition/index"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; 
import RefundPolicy from "./pages/refund/index"
import Gallery from "./pages/gallery/index"
import ProductDetail from './pages/product details';
import Enquiry from './pages/enquiry/index'
import Chairs from './pages/products/chairs';
import ScrollToTop from './components/common/header-scroll';
import Carvings from './pages/products/carvings';
import PoojaMandapam from './pages/products/pooja-mandapam';
import CNC from './pages/products/cnc';
const App = () => {

    return (
        <Router>
            <ScrollToTop />
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/products/doors" element={<Doors />} />
                <Route path="/products/chairs" element={<Chairs/>} />
                <Route path="/products/carvings" element={<Carvings/>} />
                <Route path="/products/pooja-mandapam" element={<PoojaMandapam/>} />
                <Route path="/products/cnc" element={<CNC />} />
                <Route path="/kids" element={<Kids/>} />
                <Route path="/women" element={<Women/>} />
                <Route path="/product/:id" element={<ProductDetail />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/refundpolicy" element={<RefundPolicy />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/enquiry" element={<Enquiry />} />
            </Routes>
            <Footer />
            <Help />
        </Router>
    );
};

export default App;
