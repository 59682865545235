import './contact.css';
import React, { useState } from "react";
const Contact = () => {

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: "",
    });

    const [isFormValid, setIsFormValid] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const updatedFormData = { ...formData, [name]: value };
        setFormData(updatedFormData);

        // Check if all fields are filled
        const isValid = Object.values(updatedFormData).every((field) => field.trim() !== "");
        setIsFormValid(isValid);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Format message for WhatsApp
        const whatsappMessage = `Hello, I am ${formData.name}.%0A
    Subject: ${formData.subject}%0A
    Message: ${formData.message}%0A
    Email: ${formData.email}`;

        // Open WhatsApp with the formatted message
        const whatsappUrl = `https://wa.me/7397259981?text=${whatsappMessage}`;
        window.open(whatsappUrl, "_blank");

        // Show confirmation alert
        alert("Your Enquiry has been successfully submitted!");

        // Clear form fields
        setFormData({
            name: "",
            email: "",
            subject: "",
            message: "",
        });

        setIsFormValid(false);
    };

    return (
        <>
            <section class="ftco-section bg-light pt-5" id="contact">
                <div class="container mt-0 pt-5">
                    <h1 className="heading text-center my-5">Contact Us</h1>
                    <div class="row justify-content-center">
                        <div class="col-md-12">
                            <div class="wrapper px-md-4">
                                <div class="row mb-5">
                                    <div class="col-md-3">
                                        <div class="dbox w-100 text-center">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <span class="bi bi-geo-alt icon-colorful"></span>
                                            </div>
                                            <div class="text">
                                                <p><span>Address:</span> No.5a,159 Kundrathur Main Rd, Kovur, Chennai, Tamil Nadu 600122</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="dbox w-100 text-center">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <span class="bi bi-telephone icon-colorful"></span>
                                            </div>
                                            <div class="text">
                                                <p><span>Phone:</span><a href="tel://7397259981">+91 73972 59981</a> <a href="tel://9940613493">+91 99406 13493</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="dbox w-100 text-center">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <span class="bi bi-send icon-colorful"></span>
                                            </div>
                                            <div class="text">
                                                <p><span>Email:</span> <a href="mailto:info@yoursite.com">info@srisakthiwoodworks.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="dbox w-100 text-center">
                                            <div class="icon d-flex align-items-center justify-content-center">
                                                <span class="bi bi-globe icon-colorful"></span>
                                            </div>
                                            <div class="text">
                                                <p><span>Website:</span> <a href="http://localhost:3000/">http://localhost:3000/</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div class="row p-4 no-gutters">
                                    <div className="col-md-6 p-0">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            {/* <h3 className="mb-4">Contact Us</h3> */}
                                            <form onSubmit={handleSubmit} id="contactForm" name="contactForm" className="contactForm">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group text-start">
                                                            <label className="label ms-1" htmlFor="name">Full Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="name"
                                                                id="name"
                                                                placeholder="Name"
                                                                value={formData.name}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group text-start">
                                                            <label className="label ms-1" htmlFor="email">Email Address</label>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                name="email"
                                                                id="email"
                                                                placeholder="Email"
                                                                value={formData.email}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group text-start">
                                                            <label className="label ms-1" htmlFor="subject">Subject</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="subject"
                                                                id="subject"
                                                                placeholder="Subject"
                                                                value={formData.subject}
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group text-start">
                                                            <label className="label ms-1" htmlFor="message">Message</label>
                                                            <textarea
                                                                name="message"
                                                                className="form-control"
                                                                id="message"
                                                                cols="30"
                                                                rows="4"
                                                                placeholder="Message"
                                                                value={formData.message}
                                                                onChange={handleInputChange}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button
                                                                type="submit"
                                                                className="btn btn-primary"
                                                                disabled={!isFormValid}
                                                            >
                                                                Send Message
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="col-md-6 p-0 order-md-first d-flex align-items-stretch">
                                        <div id="map" class="map" style={{ position: 'relative', overflow: 'hidden' }}>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7774.849327473638!2d80.1185408153432!3d13.008607172678822!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52603fa576ca09%3A0xc55b05964564e5a3!2sSRI%20SAKTHI%20WOOD%20WORKS!5e0!3m2!1sen!2sin!4v1731581180033!5m2!1sen!2sin" width="600" height="450" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Contact;
