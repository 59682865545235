import React, { useState } from 'react';
import './gallery.css';
// import wood1 from "../../assests/images/wood1.png"
// import wood2 from "../../assests/images/wood2.png"
// import wood3 from "../../assests/images/wood3.png"
// import wood4 from "../../assests/images/wood4.png"
// import wood5 from "../../assests/images/wood5.png"
import nodata from "../../assests/images/nodata.png"
import image1 from '../../assests/all-images/gallery/image-1.png';
import image2 from '../../assests/all-images/gallery/image-2.png';
import image3 from '../../assests/all-images/gallery/image-3.png';
import image4 from '../../assests/all-images/gallery/image-4.png';
import image5 from '../../assests/all-images/gallery/image-5.png';
import image6 from '../../assests/all-images/gallery/image-6.png';
import image7 from '../../assests/all-images/gallery/image-7.png';
import image8 from '../../assests/all-images/gallery/image-8.png';
import image9 from '../../assests/all-images/gallery/image-9.jpg';
import image10 from '../../assests/all-images/gallery/image-10.jpg';
import image11 from '../../assests/all-images/gallery/image-11.jpg';
import image12 from '../../assests/all-images/gallery/image-12.jpg';
import image13 from '../../assests/all-images/gallery/image-13.jpg';
import image14 from '../../assests/all-images/gallery/image-14.jpg';
import image15 from '../../assests/all-images/gallery/image-15.jpg';
import image16 from '../../assests/all-images/gallery/image-16.jpg';
import image17 from '../../assests/all-images/gallery/image-17.jpg';
import image18 from '../../assests/all-images/gallery/image-18.jpg';
import image19 from '../../assests/all-images/gallery/image-19.jpg';
import image20 from '../../assests/all-images/gallery/image-20.jpg';
import { FaSearch, FaTimes } from 'react-icons/fa';

const imageData = [
  { id: 1, src: image1, alt: 'Timber 1', title: 'Carved Wooden Sofa Set', description: 'Durable and strong.', category: 'Hardwood' },
  { id: 2, src: image2, alt: 'Timber 2', title: 'Grandfather Clock', description: 'Rich, reddish-brown wood.', category: 'Hardwood' },
  { id: 3, src: image3, alt: 'Timber 3', title: 'Wardrobe Cabinet', description: 'Elegant and smooth texture.', category: 'Hardwood' },
  { id: 4, src: image4, alt: 'Timber 4', title: 'Modern Wooden Shelf', description: 'Lightweight and soft.', category: 'Softwood' },
  { id: 5, src: image5, alt: 'Timber 5', title: 'Carved Wooden Chest', description: 'Dark, premium hardwood.', category: 'Hardwood' },
  { id: 6, src: image6, alt: 'Timber 6', title: 'Closet with Towels', description: 'Hardwood with fine texture.', category: 'Hardwood' },
  { id: 7, src: image7, alt: 'Timber 7', title: 'Decorative Wooden Cabinet ', description: 'Pale, durable wood with excellent strength.', category: 'Hardwood' },
  { id: 8, src: image8, alt: 'Timber 8', title: 'Wooden Horse Statue', description: 'Highly aromatic and resistant to decay.', category: 'Softwood' },
  { id: 9, src: image9, alt: 'Timber 9', title: 'Carved Wooden Panel', description: 'Light-colored wood with a smooth texture.', category: 'Hardwood' },
  { id: 10, src: image10, alt: 'Timber 10', title: 'Ornate Wooden Door', description: 'Highly durable, ideal for outdoor furniture.', category: 'Hardwood' },
  { id: 11, src: image11, alt: 'Timber 11', title: 'Rosewood Flower Sculpture', description: 'Dark brown, exotic wood with a fine finish.', category: 'Hardwood' },
  { id: 12, src: image12, alt: 'Timber 12', title: 'Elephant Wooden Figurine', description: 'Smooth texture with a pale color, ideal for furniture.', category: 'Hardwood' },
  { id: 13, src: image13, alt: 'Timber 13', title: 'Tree of Life Wall Art', description: 'Lightweight and resistant to shock, ideal for construction.', category: 'Softwood' },
  { id: 14, src: image14, alt: 'Timber 14', title: 'Elephant Face Wall Hanging', description: 'Strong and versatile, commonly used in building structures.', category: 'Softwood' },
  { id: 15, src: image15, alt: 'Timber 15', title: 'Carved Wooden Stool', description: 'Resistant to decay, often used in outdoor applications.', category: 'Softwood' },
  { id: 16, src: image16, alt: 'Timber 16', title: 'Toy Car Rocker', description: 'Strong and dense, used for tool handles and furniture.', category: 'Hardwood' },
  { id: 17, src: image17, alt: 'Timber 17', title: 'Wooden Swing', description: 'A dense, hardwood with a smooth finish, used for furniture.', category: 'Hardwood' },
  { id: 18, src: image18, alt: 'Timber 18', title: 'Circular Side Table', description: 'Highly durable, dark wood, often used in musical instruments.', category: 'Hardwood' },
  { id: 19, src: image19, alt: 'Timber 19', title: 'Carved Wooden Bed', description: 'Extremely lightweight and soft, commonly used in models.', category: 'Softwood' },
  { id: 20, src: image20, alt: 'Timber 20', title: 'Decorative Wooden Chariot', description: 'Strong and dense wood, commonly used for flooring.', category: 'Hardwood' },
];


const Gallery = () => {
  const [filter, setFilter] = useState('');
  const [selectedImage, setSelectedImage] = useState(null); // To manage the modal state
  const [visibleImages, setVisibleImages] = useState(6); // Pagination state

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const filteredImages = imageData.filter(image =>
    image.title.toLowerCase().includes(filter.toLowerCase())
  );

  const loadMoreImages = () => {
    setVisibleImages(prev => prev + 6);
  };

  return (
    <div className="container mt-0 px-4 px-md-0" style={{ padding: '120px 0px' }}>
      <div className="gallery-header">
        <div className="search-input-container">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Search by title..."
            onChange={handleFilterChange}
            value={filter}
            className='search-input'
          />
        </div>
      </div>
      <div className="row">
        {filteredImages.slice(0, visibleImages).length > 0 ? (
          filteredImages.slice(0, visibleImages).map((image) => (
            <div key={image.id} className="col-12 col-md-6 col-lg-4 mb-4">
              <div className="gallery-item" onClick={() => handleImageClick(image)}>
                <img src={image.src} alt={image.alt} className="gallery-image" />
                <div className="image-overlay">
                  <h3>{image.title}</h3>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-images">
            <img src={nodata} alt="No images found" />
            <p>No images found....</p>
          </div>
        )}
      </div>

      {filteredImages.length > visibleImages && (
        <div className="load-more text-center">
          <button onClick={loadMoreImages} className="btn btn-primary" style={{ width: '110px' }}>
            Load More
          </button>
        </div>
      )}

      {selectedImage && (
        <div className="image-modal" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <FaTimes className="modal-close-icon" onClick={handleCloseModal} />
            <img src={selectedImage.src} alt={selectedImage.alt} className="modal-image" />
            <div className="modal-info">
              <h3>{selectedImage.title}</h3>
              <p>{selectedImage.description}</p>
              <p>{selectedImage.category}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
