import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
// import img1 from "../../assests/images/img1.png";
import logo from '../../assests/all-images/logo/logo-2.png';
import cancel from "../../assests/images/cancel.png";
import menuBar from "../../assests/images/menuBar.png";
import './navbar.css'
import AppData from "../../appdata/appconfig.json"
const MyNavbar = () => {
  const [expanded, setExpanded] = useState(false);
  const [Dropdown, setDropdown] = useState(false);
  const handleToggle = () => setExpanded(!expanded);
  const handleClose = () => setExpanded(false);

  return (
    <Navbar expanded={expanded} expand="lg" bg="dark" variant="dark">
      <Container className='mt-0'>
        <div className='d-flex justify-content-between w-100 align-items-center'>
          <Navbar.Brand as={Link} to="/" className='p-0'>
            <img src={logo} alt="logo"/>
            {/* <p className='fs-5 d-inline'>Quality Trust & Innovation</p> */}
          </Navbar.Brand>
          <div><Navbar.Toggle className='border' aria-controls="navbar-nav" onClick={handleToggle} /></div>
        </div>

        {/* Navbar Links */}
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto">
            {/* Home */}
            <Nav.Link as={Link} to="/" onClick={handleClose} className='items-mobile-view'>
              Home
            </Nav.Link>
            {/* About */}
            <Nav.Link as={Link} to="/about" onClick={handleClose} className='items-mobile-view'>
              About
            </Nav.Link>
            <NavDropdown
              title="Products"
              id="products-dropdown"
              onClick={(e) => e.stopPropagation()}
              className='drop-list items-mobile-view'
            >
              <NavDropdown.Item as={Link} to="/products/doors" onClick={handleClose} className='items-mobile-view'>
                Doors
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/products/carvings" onClick={handleClose} className='items-mobile-view'>
                Carvings
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/products/chairs" onClick={handleClose} className='items-mobile-view'>
                Furnitures
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/products/pooja-mandapam" onClick={handleClose} className='items-mobile-view'>
                Divine Products
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/products/pooja-mandapam" onClick={handleClose} className='items-mobile-view'>
                Pooja Collections
              </NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/products/pooja-mandapam" onClick={handleClose} disabled={true} className='items-mobile-view' style={{color:"grey !important"}}>
                Gift Items <span>Coming Soon</span>
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/products/pooja-mandapam" onClick={handleClose} disabled={true} className='items-mobile-view' style={{color:"grey !important"}}>
                Home Docors <span>Coming Soon</span>
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/products/cnc" onClick={handleClose} className='items-mobile-view'>
                All CNC Works
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/products/architecture" onClick={handleClose} className='items-mobile-view'>
                Architecture
              </NavDropdown.Item> */}
            </NavDropdown>

            {/* Gallery */}
            <Nav.Link as={Link} to="/gallery" onClick={handleClose} className='items-mobile-view'>
              Gallery
            </Nav.Link>

            {/* Contact */}
            <Nav.Link as={Link} to="/contact" onClick={handleClose} className='items-mobile-view'>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MyNavbar;
