import React, { useEffect, useState } from 'react';
import './home.css';
import pic1 from "../../assests/images/pic1.png";
import pic2 from "../../assests/images/pic2.png";
import footer5 from "../../assests/images/footer5.jpg";
import footer6 from "../../assests/images/footer6.jpeg";
import { Carousel, Row, Col } from 'react-bootstrap';
import Card from '../../components/common/cards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag, faShoppingCart, faStar, faFire } from '@fortawesome/free-solid-svg-icons';
import cardDetail from "../../assests/images/cardDetail.jpg"
import comingSoon from "../../assests/images/comingSoon.png"
import home from "../../assests/images/Home.jpg"
import { Link, useLocation } from 'react-router-dom';
const location = useLocation;
import image1 from "../../assests/all-images/products-image/image-1.png";
import image2 from "../../assests/all-images/products-image/image-2.png";
import image3 from "../../assests/all-images/products-image/image-3.jpg";
import image4 from "../../assests/all-images/products-image/image-4.png";
import image5 from "../../assests/all-images/products-image/image-5.jpg";
import image6 from "../../assests/all-images/products-image/image-6.jpg";
import image7 from "../../assests/all-images/products-image/image-7.jpg";
import image8 from "../../assests/all-images/products-image/image-8.jpg";
import image9 from "../../assests/all-images/products-image/image-9.jpg";
import image10 from "../../assests/all-images/products-image/image-10.jpg";
import image11 from "../../assests/all-images/products-image/image-11.jpg";
import image12 from "../../assests/all-images/products-image/image-12.jpg";
import image13 from "../../assests/all-images/products-image/image-13.jpg";
import image14 from "../../assests/all-images/products-image/image-14.png";
import image15 from "../../assests/all-images/products-image/image-15.jpg";
import image16 from "../../assests/all-images/products-image/image-16.png";
import image17 from "../../assests/all-images/products-image/image-17.jpg";
import image18 from "../../assests/all-images/products-image/image-18.jpg";
import image19 from "../../assests/all-images/products-image/image-19.jpg";
import image20 from "../../assests/all-images/products-image/image-20.jpg";

// import Banner1 from "../../assests/all-images/banner/image-1.png";
import Banner1 from "../../assests/all-images/banner/image-1.png";
import Banner2 from "../../assests/all-images/banner/image-2.jpeg";
import Banner3 from "../../assests/all-images/banner/image-3.png";
import Banner4 from "../../assests/all-images/banner/image-4.jpeg";
import OfferImage from "../../assests/all-images/banner/offer-image.jpg";
import Carousel1 from "../../assests/all-images/banner/carousel-1.jpg";
import Carousel2 from "../../assests/all-images/banner/carousel-2.jpg";
import Carousel3 from "../../assests/all-images/banner/carousel-3.jpg";

const products = [
    {
        id: 1,
        name: 'Modern Door',
        price: '$199.99',
        shortDescription: 'A stylish modern door for your home.',
        image: image1
    },
    {
        id: 2,
        name: 'Classic Door',
        price: '$149.99',
        shortDescription: 'A classic wooden door with timeless appeal.',
        image: image2,
    },
    {
        id: 3,
        name: 'Double Glazed Window',
        price: '$250.00',
        shortDescription: 'Energy-efficient double glazed window.',
        image: image3,
    },
    {
        id: 4,
        name: 'Casement Window',
        price: '$180.00',
        shortDescription: 'A versatile casement window for any home.',
        image: image4,
    },
    {
        id: 5,
        name: 'Reclaimed Wood Chair',
        price: '$120.00',
        shortDescription: 'Beautiful chair made from reclaimed wood.',
        image: image5,
    },
    {
        id: 6,
        name: 'Rocking Chair',
        price: '$150.00',
        shortDescription: 'Comfortable rocking chair for your living room.',
        image: image6,
    },
    {
        id: 7,
        name: 'King Size Bed',
        price: '$800.00',
        shortDescription: 'Spacious king size bed with a wooden frame.',
        image: image7,
    },
    {
        id: 8,
        name: 'Bunk Bed',
        price: '$500.00',
        shortDescription: 'Durable bunk bed for kids or guest rooms.',
        image: image8,
    },
    {
        id: 9,
        name: 'Timber Frame House',
        price: '$450,000',
        shortDescription: 'A stunning timber frame house with eco-friendly design.',
        image: image9,
    },
    {
        id: 10,
        name: 'Rustic Timber Cabin',
        price: '$399.99',
        shortDescription: 'A rustic cabin built with solid timber, perfect for a getaway.',
        image: image1,
    },
    {
        id: 11,
        name: 'Modern Door',
        price: '$199.99',
        shortDescription: 'A stylish modern door for your home.',
        image: image11,
    },
    {
        id: 12,
        name: 'Classic Door',
        price: '$149.99',
        shortDescription: 'A classic wooden door with timeless appeal.',
        image: image12,
    },
    {
        id: 13,
        name: 'Double Glazed Window',
        price: '$250.00',
        shortDescription: 'Energy-efficient double glazed window.',
        image: image13,
    },
    {
        id: 14,
        name: 'Casement Window',
        price: '$180.00',
        shortDescription: 'A versatile casement window for any home.',
        image: image14,
    },
    {
        id: 15,
        name: 'Reclaimed Wood Chair',
        price: '$120.00',
        shortDescription: 'Beautiful chair made from reclaimed wood.',
        image: image15,
    },
    {
        id: 16,
        name: 'Rocking Chair',
        price: '$150.00',
        shortDescription: 'Comfortable rocking chair for your living room.',
        image: image16,
    },
    {
        id: 17,
        name: 'King Size Bed',
        price: '$800.00',
        shortDescription: 'Spacious king size bed with a wooden frame.',
        image: image17,
    },
    {
        id: 18,
        name: 'Bunk Bed',
        price: '$500.00',
        shortDescription: 'Durable bunk bed for kids or guest rooms.',
        image: image18,
    },
    {
        id: 19,
        name: 'Timber Frame House',
        price: '$450,000',
        shortDescription: 'A stunning timber frame house with eco-friendly design.',
        image: image19,
    },
    {
        id: 20,
        name: 'Rustic Timber Cabin',
        price: '$399.99',
        shortDescription: 'A rustic cabin built with solid timber, perfect for a getaway.',
        image: image20,
    }
];

const Home = () => {
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const limit = 8;
    const totalPages = Math.ceil(products.length / limit);

    const handleNextPage = async () => {
        if (page < totalPages) {
            setLoading(true);
            await new Promise(resolve => setTimeout(resolve, 1000));
            setPage(prevPage => prevPage + 1);
            setLoading(false);
        }
    };

    const handlePreviousPage = async () => {
        if (page > 1) {
            setLoading(true);
            await new Promise(resolve => setTimeout(resolve, 1000));
            setPage(prevPage => prevPage - 1);
            setLoading(false);
        }
    };

    const changePage = async (newPage) => {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 1000));
        setPage(newPage);
        setLoading(false);
    };


    const currentProducts = products.slice((page - 1) * limit, page * limit);

    return (
        <>
            <div className="hero-carousel-container">
                <Carousel className="custom-carousel" interval={3000} style={{ height: '100%' }}>
                    <Carousel.Item style={{ width: '100%', height: '100%' }}>
                        <Carousel.Caption style={{
                            width: '100%',
                            height: '100%',
                            padding: '0px',
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                            textAlign: 'center',
                            left: "0px",
                            right: "0px",
                            top: '0px'
                        }}>
                            <img src={Banner1} className="d-block w-100 h-100 slide-in back-image" alt="Banner 1" />
                            {/* <div
                                style={{
                                    position: 'absolute',
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                                    zIndex: 1,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    padding: '10px',
                                    borderRadius: '8px',
                                    margin: '10px',
                                    bottom: '0px',
                                }}
                            >
                                <h3 className="banner-title">Our Woodworking Expertise
                                </h3>
                                <p className="banner-description">Transforming Visions into Reality</p>
                            </div> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{ width: '100%', height: '100%' }}>
                        <Carousel.Caption style={{
                            width: '100%',
                            height: '100%',
                            padding: '0px',
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                            textAlign: 'center',
                            left: "0px",
                            right: "0px",
                            top: "0px"
                        }}>
                            <img src={Banner2} className="d-block w-100 h-100 slide-in" alt="Banner 2" />
                            {/* <div
                                style={{
                                    position: 'absolute',
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                                    zIndex: 1,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    margin: '10px',
                                    bottom: '30px',
                                }}
                            >
                                <h3 className="banner-title">Transform Your Space with Timber</h3>
                                <p className="banner-description">Explore Our Wide Range of Quality Timber Products</p>
                            </div> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{ width: '100%', height: '100%' }}>
                        <Carousel.Caption style={{
                            width: '100%',
                            height: '100%',
                            padding: '0px',
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                            textAlign: 'center',
                            left: "0px",
                            right: "0px",
                            top: "0px"
                        }}>
                            <img src={Banner3} className="d-block w-100 h-100 slide-in" alt="Banner 2" />
                            {/* <div
                                style={{
                                    position: 'absolute',
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                                    zIndex: 1,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    margin: '10px',
                                    bottom: '30px',
                                }}
                            >
                                <h3 className="banner-title">Transform Your Space with Timber</h3>
                                <p className="banner-description">Explore Our Wide Range of Quality Timber Products</p>
                            </div> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item style={{ width: '100%', height: '100%' }}>
                        <Carousel.Caption style={{
                            width: '100%',
                            height: '100%',
                            padding: '0px',
                            position: 'absolute',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'white',
                            textAlign: 'center',
                            left: "0px",
                            right: "0px",
                            top: "0px"
                        }}>
                            <img src={Banner4} className="d-block w-100 h-100 slide-in" alt="Banner 2" />
                            {/* <div
                                style={{
                                    position: 'absolute',
                                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                                    zIndex: 1,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    padding: '20px',
                                    borderRadius: '8px',
                                    margin: '10px',
                                    bottom: '30px',
                                }}
                            >
                                <h3 className="banner-title">Transform Your Space with Timber</h3>
                                <p className="banner-description">Explore Our Wide Range of Quality Timber Products</p>
                            </div> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="container">
                <div className=" my-5">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="card shadow-lg border-light p-4">
                                <div className="card-body animate__animated animate__zoomIn">
                                    <h1 className="display-4 text-center mb-4 fw-bold">Welcome To <br/><strong style={{ fontWeight: '600', color: "maroon",fontSize:'30px' }}>Sri Sakthi Wood Works </strong></h1>
                                    <p className="lead">
                                        Welcome to <strong style={{ fontWeight: '800' }}>Sri Sakthi Wood Works </strong>, where craftsmanship meets legacy. As a family-owned woodworking business,
                                        we have proudly honed our skills for over three generations. Our passion for carpentry has been passed down
                                        through the years, blending traditional techniques with modern innovations. At <strong style={{ fontWeight: '800' }}>Sri Sakthi Wood Works </strong>, we believe
                                        that every piece of wood tells a story. Our dedication to quality and detail ensures that each item we create is not
                                        only functional but also a work of art. Whether it’s custom furniture, restoration projects, or unique wooden décor,
                                        we bring our rich heritage and expertise to every project. Join us in celebrating the timeless beauty of wood, crafted
                                        with love and skill.
                                    </p>
                                    <p className="blockquote-footer text-right" style={{ fontWeight: '800' }}>
                                        "As born carpenters, we pour our passion and expertise into every project, ensuring exceptional quality and craftsmanship."
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner-img animate__animated animate__slideInLeft" style={{ color: '#fff', borderRadius: '10px' }}>
                    {/* <h1 style={{ fontSize: '2.8rem', fontWeight: 'bold', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.6)', color: '#rgb(255 255 255)' }}>
                        Exclusive Offers on fashions
                    </h1>
                    <p style={{ fontSize: '1.6rem', margin: '10px 0', textShadow: '1px 1px 5px rgba(0, 0, 0, 0.5)', color: '#ffcc99' }}>
                        Up to 50% off on selected items!
                    </p>
                    <div className="countdown-timer" style={{ margin: '20px 0', fontSize: '1.3rem', color: '#fff' }}>
                        <p style={{ margin: 0 }}>Offer ends in:</p>
                        <span id="timer" style={{ fontWeight: 'bold', color: '#rgb(255 255 255)' }}>02:34:56</span>
                    </div>
                    <div className="icons" style={{ marginBottom: '20px' }}>
                        <FontAwesomeIcon icon={faTag} className="icon" style={{ margin: '0 10px', fontSize: '1.6rem', color: '#rgb(255 255 255)' }} />
                        <FontAwesomeIcon icon={faShoppingCart} className="icon" style={{ margin: '0 10px', fontSize: '1.6rem', color: '#66cdaa' }} />
                        <FontAwesomeIcon icon={faStar} className="icon" style={{ margin: '0 10px', fontSize: '1.6rem', color: '#ffcc99' }} />
                    </div>
                    <div className="d-flex flex-column  justify-content-center align-items-center w-100 gap-2">
                        <Link to="/women">
                            <button
                                className="btn btn-light mb-3 mb-md-0 me-md-2"
                                style={{
                                    width: "100%",
                                    padding: '12px 24px',
                                    fontSize: '1.2rem',
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    border: 'none',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s',
                                }}
                            >
                                Shop Now
                            </button>
                        </Link>
                        <button
                            className="btn btn-light mb-3 mb-md-0"
                            style={{
                                width: "100%",
                                maxWidth: "250px",
                                padding: '12px 12px',
                                fontSize: '1.2rem',
                                backgroundColor: '#fff',
                                color: '#000',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s',
                            }}
                        >
                            Browse Collections
                        </button>

                    </div>

                    <div className="trending" style={{ marginTop: '20px', fontSize: '1.2rem', color: '#ffcc99' }}>
                        <FontAwesomeIcon icon={faFire} className="icon" style={{ marginRight: '5px', fontSize: '1.4rem', color: '#rgb(255 255 255)' }} />
                        Trending Now: Men's , kid's , Women's
                    </div> */}

                </div>
                <div className="home-page animate__animated animate__slideInRight">
                    <Carousel className="home-carousel" interval={1000}>
                        <Carousel.Item>
                            <Link to="/">
                                <img
                                    className="d-block w-100 rounded-image"
                                    src={Carousel1}
                                    alt="First slide"
                                />
                            </Link>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Link to="/">
                                <img
                                    className="d-block w-100 rounded-image"
                                    src={Carousel2}
                                    alt="Second slide"
                                />
                            </Link>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Link to="/">
                                <img
                                    className="d-block w-100 rounded-image"
                                    src={Carousel3}
                                    alt="Third slide"
                                />
                            </Link>
                        </Carousel.Item>
                    </Carousel>
                    <div className="mt-4">
                        <div className="card" style={{ width: '100%', padding: '20px' }}>
                            <h4>Reach out to Sri Sakthi Wood Works </h4>
                            <p>To Transform your living space into a paradise.</p>
                            <div className="shop-now-btn-main">
                                <Link to="/Kids" className="shop-now-btn">
                                    Shop Now
                                </Link>
                            </div>
                        </div>
                    </div>
                    {/* <div className='cardDetail'>
        <img src={cardDetail} alt='cardDetail' />
    </div>
    <div className='comingSoon'>
        <img src={comingSoon} alt='comingSoon' />
    </div> */}
                </div>
                <h2 className="mt-5">All Products</h2>
                <div className="row position-relative">
                    {loading && (
                        <div className="loading-overlay">
                            <div className="spinner"></div>
                        </div>
                    )}
                    {currentProducts.map(product => (
                        <div key={product.id} className="col-lg-3 col-md-6 col-sm-6 mb-4">
                            {/* <Link
                                to={{
                                    pathname: `/product/${product.id}`,
                                    state: {
                                        productId: product.id,
                                        name: product.name,
                                        image: product.image,
                                        price: product.price,
                                        shortDescription: product.shortDescription
                                    }
                                }}
                            > */}
                            <Card
                                image={product.image}
                                name={product.name}
                                price={product.price}
                                shortDescription={product.shortDescription}
                                productId={product.id}
                            />
                            {/* </Link> */}
                        </div>
                    ))}
                </div>

                <nav>
                    <ul className="pagination justify-content-center gap-2">
                        <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={handlePreviousPage} disabled={page === 1 || loading}>
                                Previous
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
                            <li key={pageNum} className={`page-item ${page === pageNum ? 'active' : ''}`}>
                                <button
                                    className="page-link"
                                    onClick={() => changePage(pageNum)}
                                    disabled={loading}
                                >
                                    {pageNum}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                            <button className="page-link" onClick={handleNextPage} disabled={page === totalPages || loading}>
                                Next
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
};


export default Home;


